import React, { ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import clsx from 'clsx'
import { defaultLang } from '@system/translations'
import { makeStyles } from '@material-ui/core/styles'
import Header from '@components/modules/global/header'
import Footer from '@components/modules/global/footer'
import MetaTags from '@components/modules/global/metatags'
import PageTransition from '@components/core/pageTransition'
import BackToTop from '@components/modules/global/backToTop'
import { Theme, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  layoutRoot: {
    display: 'flex',
    flexDirection: 'column',
    jusifyContent: 'flex-start',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
  layoutMain: {
    flex: '1 0 auto',
  },
  layoutMainHidde: {
    opacity: 0,
  },
  introFallback: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.main,
    width: '100vw',
    height: '100vh',
    zIndex: 51,
  },
}))

interface INavNodeQuery {
  node_locale: string
  pages?: Array<INavItemQuery>
}

interface INavItemQuery {
  __typename: string
  titleNavigation: string
  fields: {
    fullPath?: string
    isExternal?: boolean
  }
}

export type LayoutProps = {
  children: React.ReactNode
  pageContext: DBN.PageHelpers.PageContext
}

export default function Layout({
  children,
  pageContext,
}: LayoutProps): ReactElement {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { mainNav, footerNav } = useStaticQuery(graphql`
    query {
      mainNav: allContentfulNavigation(
        filter: { identifier: { eq: "main-navigation" } }
      ) {
        nodes {
          node_locale
          pages {
            __typename
            ... on ContentfulGenericPage {
              titleNavigation
              fields {
                fullPath
              }
            }
            ... on ContentfulExternalPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
          }
        }
      }
      footerNav: allContentfulNavigation(
        filter: { identifier: { eq: "footer-navigation" } }
      ) {
        nodes {
          node_locale
          pages {
            __typename
            ... on ContentfulGenericPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
            ... on ContentfulExternalPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
          }
        }
      }
    }
  `)

  const headerItems = mainNav.nodes
    ?.find((node: INavNodeQuery) => {
      return node.node_locale == (pageContext.locale || defaultLang)
    })
    .pages?.filter((page: INavItemQuery) => {
      return page.fields?.fullPath
    })
    .map((page: INavItemQuery) => {
      return {
        title: page.titleNavigation,
        href: page.fields?.fullPath,
        isExternal: page.fields?.isExternal,
      }
    })

  const footerItems = footerNav.nodes
    ?.find((node: INavNodeQuery) => {
      return node.node_locale === (pageContext.locale || defaultLang)
    })
    .pages?.filter((page: INavItemQuery) => {
      return page.fields?.fullPath
    })
    .map((page: INavItemQuery) => {
      return {
        title: page.titleNavigation,
        href: page.fields?.fullPath,
        isExternal: page.fields?.isExternal,
      }
    })

  return (
    <div className={clsx(classes.layoutRoot)}>
      <MetaTags
        locale={pageContext.locale}
        meta={{ canonical: pageContext.canonical }}
      />

      <Header pageContext={pageContext} navItems={headerItems} />

      <main className={clsx(classes.layoutMain)}>
        <PageTransition>{children}</PageTransition>
        {!isMobile && <BackToTop />}
      </main>

      <Footer navItems={footerItems} />
    </div>
  )
}
