import React, { ReactElement, useEffect, createContext, useState } from 'react'
import CustomCursor from '@components/core/customCursor'

export type CustomCursorProviderProps = DBN.IReactDefaultProps

export interface ICustomCursorContext {
  setCursorType: (cursor: string) => void
  disableCursor: () => void
  enableCursor: () => void
}

export interface ICustomCursorPosition {
  x: number
  y: number
}

export const CustomCursorContext = createContext<ICustomCursorContext>({
  setCursorType: () => {
    return null
  },
  disableCursor: () => {
    return null
  },
  enableCursor: () => {
    return null
  },
})

export default function CustomCursorContextProvider({
  children,
}: CustomCursorProviderProps): ReactElement {
  const [type, setType] = useState<string>('')
  const [position, setPosition] = useState<ICustomCursorPosition>({
    x: -100,
    y: -100,
  })
  const [active, setActive] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)

  const setCursorType = (cursorType: string) => {
    setType(cursorType)
  }

  const disableCursor = () => {
    setActive(false)
  }

  const enableCursor = () => {
    setActive(true)
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(hover: hover)')

    const mediaHandler = (event: MediaQueryListEvent): void => {
      if (event.matches) {
        setHover(true)
      } else {
        setHover(false)
      }
    }

    if (document.hasFocus()) {
      setActive(true)
    }

    if (mediaQuery.matches) {
      setHover(true)
    }

    const mouseMoveHandler = (event: MouseEvent) => {
      const { clientX, clientY, movementX, movementY } = event
      if (movementX !== 0 || movementY !== 0)
        setPosition({ x: clientX, y: clientY })
    }

    const mouseEnterHandler = () => {
      setActive(true)
    }

    const mouseLeaveHandler = () => {
      setActive(false)
    }

    document.body.addEventListener('mousemove', mouseMoveHandler)
    document.body.addEventListener('mouseenter', mouseEnterHandler)
    document.body.addEventListener('mouseleave', mouseLeaveHandler)
    mediaQuery.addEventListener('change', mediaHandler)
    return () => {
      document.body.removeEventListener('mousemove', mouseMoveHandler)
      document.body.removeEventListener('mouseenter', mouseEnterHandler)
      document.body.removeEventListener('mouseleave', mouseLeaveHandler)
      mediaQuery.removeEventListener('change', mediaHandler)
    }
  }, [setActive, setHover])

  return (
    <CustomCursorContext.Provider
      value={{ setCursorType, disableCursor, enableCursor }}
    >
      {children}
      {hover && active && (
        <CustomCursor type={type} x={position.x} y={position.y} />
      )}
    </CustomCursorContext.Provider>
  )
}
