import React, { ReactElement } from 'react'

const External = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <polygon points="12.6347402 4.61 16.0297402 8.00533292 12.6347402 11.3998004 10.2397402 11.4 12.6366239 9.00291667 1.24873271e-14 9.00291667 1.24873271e-14 7.00708333 12.6364244 7.00708333 10.2397402 4.61066583"></polygon>{' '}
    </svg>
  )
}

export default External
