// container width + 32px padding (l+r)

import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'

const Container: ThemeOptions['container'] = {
  sm: '736px',
  md: '920px',
  lg: '1152px',
}

export default Container
