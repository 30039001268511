import React, { ReactElement } from 'react'

const Play = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g>
        <path
          fill="#05050a"
          d="M2.73219382,0 L14.469007,8 L2.73219382,16 L2.73219382,0 Z M4.732,3.783 L4.732,12.216 L10.918,8 L4.732,3.783 Z"
        />
      </g>
    </svg>
  )
}

export default Play
