import React, { ReactElement } from 'react'
import { useLocation } from '@reach/router'
import { TransitionGroup, Transition } from 'react-transition-group'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  content: {
    '&.entering': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      opacity: 0,
    },
    '&.entered': {
      opacity: 1,
    },
    '&.exited': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      opacity: 0,
    },
  },
  curton: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    zIndex: 10000,
    '&.exiting': {
      animation: '$curtonClose',
      animationDuration: `${durationEnter - 50}ms`,
      animationTimingFunction: 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
      animationFillMode: 'forwards',
    },
    '&.entered': {
      animation: '$curtonOpen',
      animationDuration: `${durationExit - 50}ms`,
      animationTimingFunction: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
      animationFillMode: 'forwards',
    },
  },
  '@keyframes curtonClose': {
    '0%': {
      transform: 'translate(0, 100vh)',
    },
    '100%': {
      transform: 'translate(0, 0)',
    },
  },

  '@keyframes curtonOpen': {
    '0%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(0, -100vh)',
    },
  },
}))

export const durationEnter = 550
export const durationExit = 550

export type PageTransitionPros = DBN.IReactDefaultProps

export default function PageTransition({
  children,
}: PageTransitionPros): ReactElement {
  const classes = useStyles()
  const location = useLocation()

  return (
    <TransitionGroup component="div" className={classes.root}>
      <Transition
        key={location.pathname}
        timeout={{
          enter: durationEnter,
          exit: durationExit,
        }}
      >
        {(status) => (
          <div className={clsx(classes.content, status)}>
            <div className={clsx(classes.curton, status)} />
            {children}
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}
