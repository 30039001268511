const { wrapPage } = require('./src/system/createpages/pageWrap')
const { durationExit } = require('./src/components/core/pageTransition')

exports.wrapPageElement = wrapPage

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const hash = location.hash
  const savedPosition = getSavedScrollPosition(location)
  window.setTimeout(() => {
    var target = hash ? document.getElementById(hash.substring(1)) : null
    if (target) {
      target.scrollIntoView()
    } else {
      window.scrollTo(...(savedPosition || [0, 0]))
    }
  }, durationExit)

  return false
}
