import { useContext } from 'react'

import {
  CustomCursorContext,
  ICustomCursorContext,
} from '@system/providers/customCursorProvider'

export default function useCustomCursor(): ICustomCursorContext {
  const { setCursorType, disableCursor, enableCursor } =
    useContext(CustomCursorContext)

  return { setCursorType, disableCursor, enableCursor }
}
