import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Icon, { IconProps } from '@components/core/icon'
import useCustomCursor from '@system/hooks/useCustomCursor'

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    position: 'relative',
    display: 'inline-block',
    border: 0,
    outline: 0,
    background: 'none',
    padding: 0,
    font: 'inherit',
    color: 'inherit',
    '@media (hover: hover)': {
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        width: '48px',
        height: '48px',
        backgroundColor: '#29ace3',
        borderRadius: '50%',
        margin: '-24px',
        transform: 'scale(0)',
        transition: 'transform 0.3s',
      },
      '&:hover': {
        color: theme.palette.text.primary,
        '&::before': {
          transform: 'scale(1)',
        },
      },
    },
  },
  socialSvg: {
    position: 'relative',
  },
}))

export type SocialIconProps = DBN.IReactDefaultProps & {
  item: {
    name: string
    size?: IconProps['size']
    className?: string
    href?: string
    onClick?: () => void
  }
}

export default function SocialIcon({
  className,
  item,
}: SocialIconProps): ReactElement {
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()

  return item.href ? (
    <a
      href={item.href}
      rel="noopener noreferrer"
      target="_blank"
      className={clsx(className, classes.socialIcon)}
      data-testid="social-icon"
      onMouseEnter={() => setCursorType('hide')}
      onMouseLeave={() => setCursorType('')}
      onClick={() => setCursorType('')}
    >
      <Icon
        className={clsx(item.className, classes.socialSvg)}
        name={item.name}
        size={item.size}
      />
    </a>
  ) : item.onClick ? (
    <button
      onClick={item.onClick}
      className={clsx(className, classes.socialIcon)}
      data-testid="social-icon-click"
      onMouseEnter={() => setCursorType('hide')}
      onMouseLeave={() => setCursorType('')}
    >
      <Icon
        className={clsx(item.className, classes.socialSvg)}
        name={item.name}
        size={item.size}
      />
    </button>
  ) : (
    <></>
  )
}
