import MomentumScroll from './momentum.js'

const momentum = new MomentumScroll()

export const onPreRouteUpdate = () => {
  momentum.stop()
}

export const onRouteUpdate = () => {
  momentum.reset()
}
