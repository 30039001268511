// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-case-page-index-tsx": () => import("./../../../src/templates/case-page/index.tsx" /* webpackChunkName: "component---src-templates-case-page-index-tsx" */),
  "component---src-templates-error-page-index-tsx": () => import("./../../../src/templates/error-page/index.tsx" /* webpackChunkName: "component---src-templates-error-page-index-tsx" */),
  "component---src-templates-generic-page-index-tsx": () => import("./../../../src/templates/generic-page/index.tsx" /* webpackChunkName: "component---src-templates-generic-page-index-tsx" */),
  "component---src-templates-job-page-application-client-tsx": () => import("./../../../src/templates/job-page/applicationClient.tsx" /* webpackChunkName: "component---src-templates-job-page-application-client-tsx" */),
  "component---src-templates-job-page-application-tsx": () => import("./../../../src/templates/job-page/application.tsx" /* webpackChunkName: "component---src-templates-job-page-application-tsx" */),
  "component---src-templates-job-page-detail-client-tsx": () => import("./../../../src/templates/job-page/detailClient.tsx" /* webpackChunkName: "component---src-templates-job-page-detail-client-tsx" */),
  "component---src-templates-job-page-detail-tsx": () => import("./../../../src/templates/job-page/detail.tsx" /* webpackChunkName: "component---src-templates-job-page-detail-tsx" */),
  "component---src-templates-press-page-index-tsx": () => import("./../../../src/templates/press-page/index.tsx" /* webpackChunkName: "component---src-templates-press-page-index-tsx" */)
}

