import { Overrides } from '@material-ui/core/styles/overrides'
import FontSize from '@config/theme/definitions/fontSize'

export default function SvgIconOverrides(): Overrides['MuiSvgIcon'] {
  return {
    root: {
      verticalAlign: 'middle',
      fontSize: FontSize['iconDefault'],
    },
    fontSizeSmall: {
      fontSize: FontSize['iconSmall'],
    },
    fontSizeLarge: {
      fontSize: FontSize['iconLarge'],
    },
  }
}
