import React, { ReactElement } from 'react'
const ArrowDown = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <g>
        <path
          fill="#29ace3"
          d="M60,48,36,72,12,48V24L26,38V0H46V38L60,24Zm-2-.83V28.83l-14,14V2H28V42.83l-14-14V47.17l22,22Z"
        />
      </g>
    </svg>
  )
}

export default ArrowDown
