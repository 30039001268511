import { Index } from 'elasticlunr'
import React, { ReactElement, useState } from 'react'

export interface IToggleComponentContext {
  OverlayIsActive: boolean
  toggleOverlay: (show: boolean, customProps?: Partial<ICustomProps>) => void
  customProps: ICustomProps
}

interface ICustomProps {
  trackingID: string
  type: 'search' | 'default' | 'contactform'
  dialogType: 'fullscreen' | undefined
  width?: false | 'lg' | 'xs' | 'sm' | 'md' | 'xl' | undefined
  closeButton: 'default' | 'hide' | 'invert'
  className?: string
  onAcceptance: () => void
  index?: Index<DBN.SearchResult>
}
const ToggleComponentContext = React.createContext<IToggleComponentContext>({
  OverlayIsActive: false,
  toggleOverlay: () => {
    return null
  },
  customProps: {
    trackingID: '',
    type: 'default',
    dialogType: 'fullscreen',
    closeButton: 'default',
    onAcceptance: () => console.info('event'),
    index: null as unknown as Index<DBN.SearchResult>,
  },
})

export default function ToggleComponentProvider({
  children,
}: DBN.IReactDefaultProps): ReactElement {
  const [OverlayIsActive, setOverlayIsActive] = useState(false)
  const [customProps, setCustomProps] = useState<ICustomProps>({
    trackingID: '',
    type: 'default',
    dialogType: 'fullscreen',
    closeButton: 'default',
    onAcceptance: () => console.info('event'),
    index: null as unknown as Index<DBN.SearchResult>,
  })

  function toggleOverlay(
    show: boolean,
    newCustomProps?: Partial<ICustomProps>
  ): void {
    setOverlayIsActive(show)
    if (customProps) setCustomProps({ ...customProps, ...newCustomProps })
  }

  return (
    <ToggleComponentContext.Provider
      value={{ OverlayIsActive, toggleOverlay, customProps }}
    >
      {children}
    </ToggleComponentContext.Provider>
  )
}

export { ToggleComponentContext }
