import { Theme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'
import FontFamily from '../definitions/fontFamily'

export default function BaselineOverrides(
  theme: Theme
): Overrides['MuiCssBaseline'] {
  return {
    '@global': {
      '@font-face': FontFamily,
      a: {
        color: 'inherit',
        textDecoration: 'none',
        transitionProperty: 'all',
        transitionDuration: theme.transitions.duration.standard,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
      },
    },
  }
}
