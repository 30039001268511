import { useContext } from 'react'

import {
  StickyHeaderContext,
  IStickyHeaderContext,
} from '@system/providers/toggleStickyHeaderProvider'

export default function useStickyHeader(): IStickyHeaderContext {
  const { StickyHeaderIsActive, toggleStickyHeader, customProps } =
    useContext(StickyHeaderContext)

  return { StickyHeaderIsActive, toggleStickyHeader, customProps }
}
