import React, { ReactElement } from 'react'

const ArrowLeft = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <g>
        <path
          fill="#29ace3"
          d="M24,60,0,36,24,12H48L34,26H72V46H34L48,60Zm.83-2H43.17l-14-14H70V28H29.17l14-14H24.83l-22,22Z"
        />
      </g>
    </svg>
  )
}

export default ArrowLeft
