import { useContext } from 'react'

import {
  ModuleThemeContext,
  IModuleThemeContext,
} from '@system/providers/moduleThemeProvider'

export default function useModuleTheme(): IModuleThemeContext {
  const { currentTheme, changeTheme } = useContext(ModuleThemeContext)

  return { currentTheme, changeTheme }
}
