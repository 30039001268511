import SFSharpBoldWoff from '../../../static/font/SFSharp/S&FSharp-Bold.woff'
import SFSharpMediumWoff from '../../../static/font/SFSharp/S&FSharp-Medium.woff'
import SFSharpSemiboldWoff from '../../../static/font/SFSharp/S&FSharp-Semibold.woff'
/*
import SFSharpBookWoff from '../../../static/font/SFSharp/S&FSharp-Book.woff'
import SFSharpExtraboldWoff from '../../../static/font/SFSharp/S&FSharp-Extrabold.woff'
import SFSharpLightWoff from '../../../static/font/SFSharp/S&FSharp-Light.woff'
import SFSharpThinWoff from '../../../static/font/SFSharp/S&FSharp-Thin.woff'
import SFSharpLogotypeBoldWoff from '../../../static/font/SFSharp/S&FSharpLogotype-Bold.woff'
*/
const SFSharpBold = {
  fontFamily: 'SFSharpBold',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${SFSharpBoldWoff}) format('woff')
  `,
}

const SFSharpSemibold = {
  fontFamily: 'SFSharpSemibold',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${SFSharpSemiboldWoff}) format('woff')
  `,
}

const SFSharpMedium = {
  fontFamily: 'SFSharpMedium',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${SFSharpMediumWoff}) format('woff')
  `,
}


/*
const SFSharpBook = {
  fontFamily: 'S&FSharp-Book',
  fontStyle: 'normal',
  // fontWeight: 400,
  src: `
    url(${SFSharpBookWoff}) format('woff')
  `,
}

const SFSharpExtrabold = {
  fontFamily: 'S&FSharp-Extrabold',
  fontStyle: 'normal',
  // fontWeight: 400,
  src: `
    url(${SFSharpExtraboldWoff}) format('woff')
  `,
}

const SFSharpLight = {
  fontFamily: 'S&FSharp-Light',
  fontStyle: 'normal',
  // fontWeight: 400,
  src: `
    url(${SFSharpLightWoff}) format('woff')
  `,
}



const SFSharpThin = {
  fontFamily: 'S&FSharp-Thin',
  fontStyle: 'normal',
  // fontWeight: 400,
  src: `
    url(${SFSharpThinWoff}) format('woff')
  `,
}

const SFSharpLogotypeBold = {
  fontFamily: 'S&FSharp-LogotypeBold',
  fontStyle: 'normal',
  // fontWeight: 400,
  src: `
    url(${SFSharpLogotypeBoldWoff}) format('woff')
  `,
}

*/

export default [SFSharpBold, SFSharpSemibold, SFSharpMedium /*, SFSharpBook, SFSharpExtrabold, SFSharpLight, SFSharpThin, SFSharpLogotypeBold */ ]
