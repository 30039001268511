import React, { ReactElement } from 'react'
const ArrowRight = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
    >
      <g>
        <path
          fill="#29ace3"
          d="M48,12,72,36,48,60H24L38,46H0V26H38L24,12Zm-.83,2H28.83l14,14H2V44H42.83l-14,14H47.17l22-22Z"
        />
      </g>
    </svg>
  )
}

export default ArrowRight
