import { throttle } from './utils.js'

export default class MomentumScroll {
  constructor() {
    this.target =
      document.scrollingElement ||
      document.documentElement ||
      document.body.parentNode ||
      document.body

    this.speed = 40
    this.pos = this.target.scrollTop
    this.moving = false
    this.requestID = null

    const onScroll = function () {
      setCurrentPosition()
    }

    const onWheel = function (event) {
      event.preventDefault()
      setTargetPosition(event)
    }

    const normalizeWheelDelta = function (event) {
      if (event.detail) {
        if (event.wheelDelta)
          return (
            (event.wheelDelta / event.detail / 40) * (event.detail > 0 ? 1 : -1)
          )
        // Opera
        else return -event.detail / 3 // Firefox
      } else return event.wheelDelta / 120 // IE,Safari,Chrome
    }

    const setCurrentPosition = throttle(
      function () {
        if (!this.moving) {
          this.pos = this.target.scrollTop
        }
      },
      10,
      this
    )

    const setTargetPosition = throttle(
      function (event) {
        const delta = normalizeWheelDelta(event)

        this.pos += -delta * this.speed
        this.pos = Math.max(
          0,
          Math.min(
            this.pos,
            this.target.scrollHeight - this.target.clientHeight
          )
        )
        if (!this.moving) {
          this.update()
        }
      },
      10,
      this
    )

    this.target.addEventListener('mousewheel', onWheel, { passive: false })
    this.target.addEventListener('DOMMouseScroll', onWheel, { passive: false })
    window.addEventListener('scroll', onScroll, { passive: false })
  }

  update() {
    this.moving = true
    let delta = this.pos - this.target.scrollTop

    delta =
      delta < 0
        ? -Math.pow(this.target.scrollTop - this.pos, 3 / 5)
        : Math.pow(this.pos - this.target.scrollTop, 3 / 5)

    this.target.scrollTop += delta

    if (this.onUpdate) {
      this.onUpdate(this.target.scrollTop)
    }

    if (Math.abs(delta) > 1) {
      this.requestID = requestAnimationFrame(this.update.bind(this))
    } else {
      this.moving = false
    }
  }

  stop() {
    if (this.moving) {
      cancelAnimationFrame(this.requestID)
      this.pos = this.target.scrollTop
      this.moving = false
    }
  }

  reset() {
    this.pos = this.target.scrollTop
  }
}
