import React, { ReactElement } from 'react'

import ArrowLeft from './icons/ArrowLeft'
import ArrowRight from './icons/ArrowRight'
import ArrowDown from './icons/ArrowDown'
import Play from './icons/Play'
import Pause from './icons/Pause'
import More from './icons/More'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  cursor: {
    position: 'fixed',
    width: '24px',
    height: '24px',
    backgroundColor: '#29ace3',
    border: '2px solid #29ace3',
    boxSizing: 'border-box',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)',
    transition: 'width 0.3s ease-out, height 0.3s ease-out',
    willChange: 'width, height, transform, border',
    zIndex: 999999,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.hide': {
      width: '0px',
      height: '0px',
      backgroundColor: '#29ace3',
      border: '0px solid #29ace3',
    },
    '&.link': {
      width: '48px',
      height: '48px',
      backgroundColor: '#29ace3',
      border: '2px solid #29ace3',
    },
    '&.teaserMore': {
      width: '72px',
      height: '72px',
      backgroundColor: '#29ace3',
      border: '2px solid #29ace3',
      color: '#05050a',
    },
    '&.teaserExternal': {
      width: '72px',
      height: '72px',
      backgroundColor: '#29ace3',
      border: '2px solid #29ace3',
      color: '#05050a',
      '& svg': {
        display: 'block',
        transform: 'rotate(-45deg)',
        transformOrigin: 'center center',
      },
    },
    '&.arrow-left': {
      width: '72px',
      height: '72px',
      borderColor: '#29ace300',
      backgroundColor: '#29ace300',
    },

    '&.arrow-right': {
      width: '72px',
      height: '72px',
      borderColor: '#29ace300',
      backgroundColor: '#29ace300',
    },

    '&.arrow-down': {
      width: '72px',
      height: '72px',
      borderColor: '#29ace300',
      backgroundColor: '#29ace300',
    },

    '&.play': {
      width: '72px',
      height: '72px',
      borderColor: '#29ace',
      backgroundColor: '#29ace',
    },
    '&.play-pause': {
      width: '72px',
      height: '72px',
      borderColor: '#29ace',
      backgroundColor: '#29ace',
    },
  },
  focus: {
    opacity: 1,
  },
  '@global': {
    '*': {
      cursor: 'none',
    },
  },
}))

export type CustomCursorProps = {
  x: number
  y: number
  type: string
}

export default function CustomCursor({
  x,
  y,
  type,
}: CustomCursorProps): ReactElement {
  const classes = useStyles()

  const getIcon = (icon: string) => {
    switch (icon) {
      case 'arrow-left':
        return <ArrowLeft />
      case 'arrow-right':
        return <ArrowRight />
      case 'arrow-down':
        return <ArrowDown />
      case 'play':
        return <Play />
      case 'play-pause':
        return <Pause />
      case 'teaserExternal':
        return <More />
      case 'teaserMore':
        return <More />
    }
    return <span />
  }
  return (
    <div
      className={clsx(classes.cursor, type)}
      style={{ left: `${x}px`, top: `${y}px` }}
    >
      {getIcon(type)}
    </div>
  )
}
