import React, { ReactElement } from 'react'

const Pause = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g>
        <path
          fill="#05050a"
          d="M6.5,0 L6.5,16 L0,16 L0,0 L6.5,0 Z M16,0 L16,16 L9.5,16 L9.5,0 L16,0 Z M4.7,1.8 L1.8,1.8 L1.8,14.2 L4.7,14.2 L4.7,1.8 Z M14.2,1.8 L11.3,1.8 L11.3,14.2 L14.2,14.2 L14.2,1.8 Z"
        />
      </g>
    </svg>
  )
}

export default Pause
