import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import useCustomCursor from '@system/hooks/useCustomCursor'
import Icon from '@components/core/icon'

const useStyles = makeStyles((theme) => ({
  backToTop: {
    background: theme.palette.secondary.light,
    zIndex: 1000,
    borderRadius: '50%',
    padding: theme.spacing(2),
    '&:hover': {
      padding: theme.spacing(3),
      right: theme.spacing(5),
    },

    transition: 'all .2s ease-in-out',
    position: 'fixed',
    right: theme.spacing(-25),
    top: 'auto',
    bottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      right: theme.spacing(5),
      top: theme.spacing(5),
      bottom: 'auto',
    },
    '& svg': {
      color: theme.palette.common.black,
    },
  },
  backToTopVisible: {
    right: theme.spacing(5),
  },
}))

export default function BackToTop(): React.ReactElement {
  const [showScroll, setShowScroll] = useState(false)
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()
  const [lastScrollTop, setLastScrollTop] = useState(0)

  const checkScrollTop = () => {
    const scrollingTopPos = window.scrollY || document.documentElement.scrollTop
    setShowScroll(scrollingTopPos <= lastScrollTop && scrollingTopPos > 300)
    setLastScrollTop(scrollingTopPos <= 0 ? 0 : scrollingTopPos)
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return () => window.removeEventListener('scroll', checkScrollTop)
  }, [lastScrollTop])

  return (
    <div
      onMouseEnter={() => setCursorType('hide')}
      onMouseLeave={() => setCursorType('')}
      className={clsx(classes.backToTop, {
        [classes.backToTopVisible]: showScroll,
      })}
      onClick={scrollTop}
    >
      <Icon name="ArrowUp" size="large" />
    </div>
  )
}
