import React, { ReactElement, useState } from 'react'

export interface IStickyHeaderContext {
  StickyHeaderIsActive: boolean
  toggleStickyHeader: (
    show: boolean,
    customProps?: Partial<ICustomProps>
  ) => void
  customProps: ICustomProps
}

interface ICustomProps {
  slug: string
}
const StickyHeaderContext = React.createContext<IStickyHeaderContext>({
  StickyHeaderIsActive: false,
  toggleStickyHeader: () => {
    return null
  },
  customProps: {
    slug: '',
  },
})

export default function StickyHeaderProvider({
  children,
}: DBN.IReactDefaultProps): ReactElement {
  const [StickyHeaderIsActive, setStickyHeaderIsActive] = useState(false)
  const [customProps, setCustomProps] = useState<ICustomProps>({
    slug: '',
  })

  function toggleStickyHeader(
    show: boolean,
    newCustomProps?: Partial<ICustomProps>
  ): void {
    setStickyHeaderIsActive(show)
    if (customProps) setCustomProps({ ...customProps, ...newCustomProps })
  }

  return (
    <StickyHeaderContext.Provider
      value={{ StickyHeaderIsActive, toggleStickyHeader, customProps }}
    >
      {children}
    </StickyHeaderContext.Provider>
  )
}

export { StickyHeaderContext }
