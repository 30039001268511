import React, { ReactElement, ReactNode } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import useCustomCursor from '@system/hooks/useCustomCursor'
import Icon, { IconProps } from '@components/core/icon'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > span': {
      position: 'relative',
      display: 'inline-block',
      '&::after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: '0',
        height: '1px',
        bottom: '0',
        left: '0',
        backgroundColor: 'currentColor',
        transition: 'width 0.3s ease',
      },
    },
    '@media (hover: hover)': {
      '&:hover': {
        '& > span': {
          '&::after': {
            width: '100%',
          },
        },
      },
    },
  },
  active: {
    '& > span': {
      '&::after': {
        width: '100%',
      },
    },
  },
  icon: {
    marginLeft: theme.spacing(2),
    verticalAlign: 'middle',
  },
}))

export type LinkProps = {
  children?: ReactNode
  className?: string
  to: string
  isExternal?: boolean | false
  isActive?: boolean | false
  icon?: IconProps
}

export default function Link({
  className,
  children,
  to,
  isExternal,
  isActive,
  icon,
  ...props
}: LinkProps): ReactElement {
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()

  if (to === '#usercentrics-revoke') {
    return (
      <a
        className={clsx(className, classes.root, {
          [classes.active]: isActive,
        })}
        onClick={() => window.UC_UI.showSecondLayer()}
      >
        <span>{children}</span>
      </a>
    )
  } else if (isExternal) {
    return (
      <a
        className={clsx(className, classes.root, {
          [classes.active]: isActive,
        })}
        href={to}
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setCursorType('link')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        <span>{children}</span>
        {icon && (
          <Icon
            name={icon.name}
            size={icon.size}
            className={clsx(icon.className, classes.icon)}
            color={icon.color}
          />
        )}
      </a>
    )
  } else {
    return (
      <GatsbyLink
        className={clsx(className, classes.root, {
          [classes.active]: isActive,
        })}
        to={to}
        onMouseEnter={() => setCursorType('link')}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
        {...props}
      >
        <span>{children}</span>
        {icon && (
          <Icon
            name={icon.name}
            size={icon.size}
            className={clsx(icon.className, classes.icon)}
            color={icon.color}
          />
        )}
      </GatsbyLink>
    )
  }
}
