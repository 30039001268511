import React, { ReactElement, ReactNode } from 'react'
import Trunc from 'truncate-html'
import parse from 'html-react-parser'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    marginBottom: theme.spacing(8),
    hyphens: 'auto',
  },
}))

export type ParagraphProps = DBN.IReactDefaultProps & {
  truncate?: number
  truncateextend?: ReactNode
}

export default function Paragraph({
  className,
  children,
  truncate,
  truncateextend,
}: ParagraphProps): ReactElement {
  const classes = useStyles()

  function TruncateOrNot(text: typeof React.Children | unknown) {
    if (truncate) {
      return Trunc(text, truncate, { byWords: true })
    }
    if (typeof text == 'string')
      return parse(
        text
          .replaceAll('  ', '<br>')
          .replaceAll('®', '<sup>®</sup>')
          .replaceAll('©', '<sup>©</sup>')
          .replaceAll('℗', '<sup>℗</sup>')
      )
    return text
  }

  return (
    <p className={clsx(className, classes.root)}>
      {!!children && TruncateOrNot(children)}
      {truncateextend}
    </p>
  )
}
